import type { AccountSummary, Rewards, Statement } from "mkm-types";
import { toRefs } from "@vueuse/core";

const INVALID_REWARDS_STATUSES: Set<string> = new Set(["D", "N", "E"]);

const displayRewardsClub = (rewards: Rewards): boolean => {
  return !INVALID_REWARDS_STATUSES.has(rewards.status);
};

const getAccountBalance = (statement: Array<Statement>): number => {
  return statement.find((record: Statement) => record.label === "Total account balance")?.value || 0;
};

export interface UseStatementState {
  accountSummary: AccountSummary | null;
  accountBalance: number;
  displayRewardsClub: boolean;
  errorFetchingStatement: boolean;
  loadingStatement: boolean;
}

const useStatement = () => {
  const state = useState<UseStatementState>(`useStatement`, () => ({
    accountSummary: null,
    accountBalance: 0,
    displayRewardsClub: false,
    errorFetchingStatement: false,
    loadingStatement: false,
  }));

  const fetchStatement = async () => {
    const { NUXT_PUBLIC_API_PROTOCOL, NUXT_PUBLIC_API_BASE_URL } = useRuntimeConfig().public;
    const headers = useRequestHeaders();

    state.value.loadingStatement = true;

    const { data: accountSummary, error } = await useAsyncData("getAccountDetails", () =>
      $fetch<AccountSummary>(`${NUXT_PUBLIC_API_PROTOCOL}://${NUXT_PUBLIC_API_BASE_URL}/middleware/getAccountDetails`, {
        headers,
        method: "POST",
        credentials: "include",
      }),
    );

    if (error.value) {
      state.value.accountSummary = null;
      state.value.errorFetchingStatement = true;
      state.value.loadingStatement = false;

      return;
    }

    if (!accountSummary.value) {
      return;
    }

    state.value.accountSummary = accountSummary.value;

    if (accountSummary.value.statement) state.value.accountBalance = getAccountBalance(accountSummary.value.statement);

    if (accountSummary.value.rewards) state.value.displayRewardsClub = displayRewardsClub(accountSummary.value.rewards);

    state.value.errorFetchingStatement = false;
    state.value.loadingStatement = false;
  };

  return {
    fetchStatement,
    ...toRefs(state.value),
  };
};

export default useStatement;
